import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import AboutUs from "./pages/about/AboutUs";
import DirectorMessage from "./pages/directormessage/DirectorMessage";
import Facility from "./pages/facility/Facility";
import Activities from "./pages/activities/Activities";
import Admission from "./pages/admission/Admission";
import TopResult from "./pages/home/HomeTopResult";
import Gallery from "./pages/gallery/Gallery";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import Result from "./pages/result/Result";
import ContactTesting from "./pages/contact/ContactTesting";
export const metadata = {
  title:
      "best admire convent school rewa, top best admire convent school rewa, top admire convent school rewa, best school, rewa school.",
  description:
      "One of the top providers of health and safety courses in India is Admire Convent School. is endorsed by the Indian government.During the course of its more than ten years of operation Admire Convent School, which opened its doors in 2014, has accomplished a great deal and maintained an integrated development of all services to meet the needs of the students. Thousands of students careers in the health and safety sector have been significantly influenced by us Additionally, we continued our tradition of quality control.",
  keywords:
      "best admire convent school rewa, top best admire convent school rewa, top admire convent school rewa, best school, rewa school.",
};


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainLayOut />}>
          <Route index element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/DirectorMessage" element={<DirectorMessage />} />
          <Route path="/Facility" element={<Facility />} />
          <Route path="/Activities" element={<Activities />} />
          <Route path="/Admission" element={<Admission />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Blog" element={<Blog />} />
          {/* <Route path="/Contact" element={<Contact />} /> */}
          <Route path="/Contact" element={<ContactTesting />} />
          <Route path="/Result" element={<Result />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
